import * as React from 'react'
import Layout from '../../../../layout'
import LeaderboardsPage from '../../../../pagesComponents/leaderboards'

const Leaderboards = ({ groupId, groupMemberId }) => {
	return (
		<Layout title="Group Leaderboard">
			<LeaderboardsPage groupId={groupId} groupMemberId={groupMemberId} />
		</Layout>
	)
}

export default Leaderboards
